const navTranslates = [
  {
    title: {
      cz: "PROLOTERAPIE",
      eng: "PROLOTHERAPY",
    },
  },
]

const mainTranslates = [
  {
    hero: {
      cz: "Proloterapie je léčebná metoda založená na stimulaci přirozeného procesu hojení těla.",
      eng: "Prolotherapy is a treatment method based on stimulation of a natural healing process of the body.",
    },
  },
  {
    helpsWithTitle: {
      cz: "S čím mi pomůže?",
      eng: "How does it help?",
    },
  },
  {
    helpsWith: {
      cz: "Proloterapie se ve Spojených státech a v západní Evropě již více než 50 let úspěšně používá při léčbě poškozených vazů, šlach, kloubů a meziobratlových plotének.",
      eng: "In United States and Western Europe the Prolotherapy is successfully used for treatment of damage vases, joints and intervertebral discs more than 50 years.",
    },
  },
  {
    advantagesTitle: {
      cz: "Výhody této metody",
      eng: "Advantages",
    },
  },
  {
    advantages: {
      cz: "Hlavní výhodou proloterapie je její vysoká úspěšnost (80%), minimální vedlejší účinky a ambulantní styl léčby. Většinou není nutná ani pracovní neschopnost.",
      eng: "The main advantage of the prolotherapy is its high success rate (80%), minimum of side effects and outpatient treatment. Mostly a sick note is not necessary.",
    },
  },
  {
    implementationTitle: {
      cz: "Jak se to provádí?",
      eng: "Implementation",
    },
  },
  {
    implementation: {
      cz: "Proloterapeutická stimulace se provádí sérií injekcí, jejichž hlavní složkou je cukerný roztok z dextrózy.",
      eng: "The Prolotherapy stimulation is implemented as a sequence of injections with a sugar solution of dextrose as a main component.",
    },
  },
  {
    briefDescription: {
      cz: "Proloterapie je metodou 1.volby u většiny výhřezů meziobratlových plotének. Operace pouze u těch případů, kdy hrozí ztráta motoriky neboli hybnosti.",
      eng: "Prolotherapy is the first choice for an intervertebral discs prolaps. An operation is necessary only in case of impending motor-skills loss.",
    },
  },
  {
    treatmentDescriptionTitle: {
      cz: "Detailní popis léčby",
      eng: "Treatment description in details",
    },
  },
  {
    treatmentParagraphOne: {
      cz: "Pacientovi je na léčené místo injekčně vpraven roztok dextrózy, který vytvoří lokální zánět.",
      eng: "The solution of dextrose is injected to the patient’s treated part, where it causes a local inflammation.",
    },
  },
  {
    treatmentParagraphTwo: {
      cz: "Tím dojde k mobilizací imunitního systému, který zasáhne a začne toto místo intenzivně regenerovat.",
      eng: "The inflammation mobilizes an immune system, which starts with an intensive regeneration of the treated part.",
    },
  },
  {
    treatmentParagraphThree: {
      cz: "Injekční aplikace trvá několik málo vteřin a je velmi dobře snášena i méně statečnými.",
      eng: "Injection application lasts only few seconds and is very well endured.",
    },
  },
  {
    treatmentParagraphFour: {
      cz: "Pro hluboké proléčení je třeba aplikaci v týdenních intervalech několikrát opakovat. Maximálně však 10 týdnů",
      eng: "For deep regeneration it is necessary to repeat the injection several times in week intervals, max.  10 weeks in total.",
    },
  },
  {
    treatmentParagraphFive: {
      cz: "Účinek léčby proloterapií je trvalý a není třeba jej obnovovat.",
      eng: "The effect of prolotherapy is permanent and there is no need to repeat it.",
    },
  },
  {
    formTitle: {
      cz: "Domluvte si termín své konzultace",
      eng: "Manage your appointment",
    },
  },
  {
    formName: {
      cz: "Jméno a příjmení",
      eng: "Name and surname",
    },
  },
  {
    formNameError: {
      cz: "Jak Vám máme říkat?",
      eng: "What is your name?",
    },
  },
  {
    formTel: {
      cz: "Telefon",
      eng: "Phone",
    },
  },
  {
    formTelError: {
      cz: "Kam Vám můžeme zavolat?",
      eng: "What is your phone number?",
    },
  },
  {
    formEmailError: {
      cz: "Je tento email správný?",
      eng: "Is the email correct?",
    },
  },
  {
    formBriefDescription: {
      cz: "Krátký popis potíží",
      eng: "Brief description of your problem",
    },
  },
  {
    formBriefDescriptionError: {
      cz: "Co Vás trápí?",
      eng: "Brief description of your problem",
    },
  },
  {
    formSend: {
      cz: "ODESLAT",
      eng: "SEND",
    },
  },
  {
    formErrorTitle: {
      cz: "Zdá se, že formulář nemá svůj den a zpráva přes něj poslat nepůjde.",
      eng: "It seems that the form does not have its day and the message cannot be sent through it.",
    },
  },
  {
    formErrorMessage: {
      cz: "Kontaktujte nás prosím telefonicky nebo emailem a domluvte si nezávaznou konzultaci.",
      eng: "Please contact us by phone or email to arrange a no-obligation consultation.",
    },
  },
  {
    onlyProloterapeutTitle: {
      cz: "Dr. Půlpán, jediný PROLOTERAPEUT v České a Slovenské republice.",
      eng: "Dr. Půlpán, the only PROLOTHERAPIST in Czechia and Slovakia.",
    },
  },
  {
    moreThanThirtyYearsExperience: {
      cz: "Více než 30 let praxe u nás i v zahraničí",
      eng: "More than 30 years of practice in Czechia and in abroad",
    },
  },
  {
    tillNinety: {
      cz: "Do roku 1990:",
      eng: "Till 1990:",
    },
  },
  {
    tillNinetyFirst: {
      cz: "Ortopedická klinika ILF v Praze na Bulovce (zároveň osobní lékař dvacetinásobných mistrů světa v kolové bratrů Pospíšilových)",
      eng: "Orthopedic clinic of the 1st medical faculty in Prague, Bulovka, simultaneously, private doctor of brothers Pospíšil (twenty-times winners of the World Championship in cycling)",
    },
  },
  {
    tillNinetySecond: {
      cz: "Česká atestace z ortopedie",
      eng: "Czech orthopedic attestation",
    },
  },
  {
    fromNinety: {
      cz: "Od roku 1990:",
      eng: "From 1990:",
    },
  },
  {
    fromNinetyFirst: {
      cz: "Ortopedická Universitní klinika Heidelberg (zároveň lékař německých reprezentantů v cyklistice, vzpíraní, judu v Olympijském středisku Heidelberg)",
      eng: "Orthopedic university clinic in Heidelberg, simultaneously doctor of German representation in cycling, weight-lifting and judo in Olympic centre Heidelberg",
    },
  },
  {
    fromNinetySecond: {
      cz: "Začíná provádět konservativní léčbu výhřezu meziobratlové ploténky (Proloterapii)",
      eng: "starts to perform a conservative treatment method of prolapsed intervertebral discs (Prolotherapy)",
    },
  },
  {
    fromNinetyThird: {
      cz: "Léčbu artrózy kloubů bez operace (preparátem Hyalart od firmy Bayer)",
      eng: "Treatment of joint arthritis without operation (by Hyalart from Bayer company)",
    },
  },
  {
    fromNinetyFourth: {
      cz: "Německá atestace z ortopedie (říjen 1994)",
      eng: "German orthopedic attestation (October 1994)",
    },
  },
  {
    acupuncture: {
      cz: "Akupunktura celotělní",
      eng: "Whole-body acupuncture",
    },
  },
  {
    diplomHeidelberg: {
      cz: "Diplom z Ortopedické univerzitní kliniky Heidelberg",
      eng: "Diploma from the Orthopedic University Clinic Heidelberg",
    },
  },
  {
    chiro: {
      cz: "Manipulační léčba(Chirotherapie)",
      eng: "Chirotheraphy",
    },
  },
]

const footerTranslates = [
  {
    atest: {
      cz: "Česká a německá atestace",
      eng: "Czech and German attestation",
    },
  },
  {
    office: {
      cz: "Ordinace",
      eng: "Office",
    },
  },
  {
    contact: {
      cz: "Kontakt",
      eng: "Contact",
    },
  },
  {
    links: {
      cz: "Odkazy",
      eng: "Links",
    },
  },
]

export const getTranslationsFor = (lang: string) => {
  const nav = {}
  const main = {}
  const footer = {}
  navTranslates.forEach(e => {
    nav[Object.keys(e)[0]] = e[Object.keys(e)[0]][lang]
  })
  mainTranslates.forEach(e => {
    main[Object.keys(e)[0]] = e[Object.keys(e)[0]][lang]
  })
  footerTranslates.forEach(e => {
    footer[Object.keys(e)[0]] = e[Object.keys(e)[0]][lang]
  })
  return {
    nav,
    main,
    footer,
  }
}
